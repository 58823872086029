import { BufferAttribute, BufferGeometry, Group, Points, PointsMaterial } from "three";
import { dispose } from "viewer/utils";
import { ActiveCameraChangeEvent, camera, scene } from "viewer/core";

export class Volume {
  private readonly pointsAboveMaterial: PointsMaterial;
  private readonly pointsBelowMaterial: PointsMaterial;
  private lastRenderedPerspectivePointSize: number = 0;

  private group: Group = new Group();
  private progressGroup: Group = new Group();

  private vectorsAbove: number[] = [];
  private vectorsBelow: number[] = [];

  private visible = false;
  private progressVisible = false;

  constructor() {
    this.group.matrixAutoUpdate = false;
    this.progressGroup.matrixAutoUpdate = false;
    this.pointsAboveMaterial = new PointsMaterial({ color: "#ff0059", size: 0.01 });
    this.pointsBelowMaterial = new PointsMaterial({ color: "#0073ff", size: 0.01 });

    scene.add(this.group, this.progressGroup);

    window.addEventListener(ActiveCameraChangeEvent, () => this.rerenderPoints());
  }

  public renderPoints(vectors: { above: Float32Array; below: Float32Array }, precision = 0.01): void {
    if (!this.progressVisible) {
      const isOrthographic = camera.activeCamera === "orthographic";
      const perspectiveSize = (this.lastRenderedPerspectivePointSize = precision * 1.5);
      const size = isOrthographic ? 1 : perspectiveSize;
      const shift = isOrthographic ? 0.001 : size / 2;

      this.pointsAboveMaterial.size = size;
      this.pointsBelowMaterial.size = size;
      this.progressGroup.position.setY(shift);
      this.progressGroup.updateMatrix();
      this.progressVisible = true;
    }

    this.vectorsAbove.push(...vectors.above);
    this.vectorsBelow.push(...vectors.below);

    this.progressGroup.add(
      new Points(Volume.createGeometry(vectors.above), this.pointsAboveMaterial),
      new Points(Volume.createGeometry(vectors.below), this.pointsBelowMaterial),
    );
  }

  private rerenderPoints() {
    if (!this.visible) return;

    const isOrthographic = camera.activeCamera === "orthographic";
    const size = isOrthographic ? 1 : this.lastRenderedPerspectivePointSize;
    const shift = isOrthographic ? 0.001 : size / 2;

    this.pointsAboveMaterial.size = size;
    this.pointsBelowMaterial.size = size;
    (this.group.children[0] as Points).material = this.pointsAboveMaterial;
    (this.group.children[1] as Points).material = this.pointsBelowMaterial;
    this.progressGroup.position.setY(shift);
    this.progressGroup.updateMatrix();
  }

  public mergePoints(): void {
    this.group.position.setY(this.progressGroup.position.y);
    this.remove(this.progressGroup);
    this.progressVisible = false;

    this.group.add(
      new Points(Volume.createGeometry(Float32Array.from(this.vectorsAbove)), this.pointsAboveMaterial),
      new Points(Volume.createGeometry(Float32Array.from(this.vectorsBelow)), this.pointsBelowMaterial),
    );

    this.vectorsAbove = [];
    this.vectorsBelow = [];

    this.group.updateMatrix();
    this.show();
  }

  public remove(group: Group): void {
    group.children.forEach((points) => dispose(points as Points));
    group.children = [];
    group.updateMatrix();
  }

  public removePoints(): void {
    this.remove(this.progressGroup);
    this.progressVisible = false;
    this.remove(this.group);
    this.visible = false;
    this.vectorsAbove = [];
    this.vectorsBelow = [];
  }

  public show(): void {
    if (this.visible) return;

    this.group.visible = true;
    this.visible = true;
  }

  public hide(): void {
    if (!this.visible) return;

    this.group.visible = false;
    this.visible = false;
  }

  public cleanup(): void {
    this.removePoints();
    this.pointsAboveMaterial.dispose();
    this.pointsBelowMaterial.dispose();
    scene.remove(this.group, this.progressGroup);
  }

  private static createGeometry(vectors: Float32Array) {
    return new BufferGeometry().setAttribute("position", new BufferAttribute(vectors, 3));
  }
}
