import {
  BufferAttribute,
  BufferGeometry,
  ColorRepresentation,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  Vector3,
} from "three";
import { List } from "immutable";
import { scene } from "viewer/core";
import { dispose, disposeLineMaterial, getVectorsOnModel } from "viewer/utils";
import { RealProjected } from "viewer/measurement/interface";
import { MeasurementValues } from "viewer/measurement/Values";

const DEFAULT_COLOR = "#ffff00";
const DEFAULT_INVALID_COLOR = "#ff0000";

export class Lines {
  private lines = List<Line>();
  private lineLengths = List<RealProjected>();

  private model?: Group;
  private raycast = false;

  public setModel(model: Group): void {
    this.model = model;
  }

  public setRaycast(raycast: boolean): void {
    this.raycast = raycast;
  }

  /** Returns all lines lengths in array */
  public get lengths(): RealProjected[] {
    return this.lineLengths.toArray();
  }

  /** Adds new line */
  public add(start: Vector3, end: Vector3): void {
    const raycastedVectors = this.raycast && this.model ? getVectorsOnModel(start, end, this.model) : undefined;
    const vectors = raycastedVectors || [start.clone(), end.clone()];

    this.lines = this.lines.push(Lines.create(vectors));
    this.lineLengths = this.lineLengths.push(MeasurementValues.calculateDistanceFromArray(vectors));
  }

  /** Adds new lines from array of vectors */
  public addFromArray(vectors: Vector3[]): void {
    if (vectors.length < 2) return;

    for (let i = 0; i < vectors.length - 1; i++) {
      this.add(vectors[i], vectors[i + 1]);
    }
  }

  /** Adds line between last and first vector in input array */
  public close(vectors: Vector3[]): void {
    if (vectors.length < 2) return;

    this.add(vectors[vectors.length - 1], vectors[0]);
  }

  /** Removes line with given index */
  public remove(index: number): void {
    if (index === -1) index = this.lines.size - 1;

    this.dispose(this.lines.get(index));
    this.lines = this.lines.delete(index);
    this.lineLengths = this.lineLengths.delete(index);
  }

  /** Updates line with given index - returns whether new line position is valid */
  public update(index: number, start: Vector3, end: Vector3): boolean {
    const line = this.lines.get(index);

    if (!line || !start || !end) return false;

    const raycastedVectors = this.raycast && this.model ? getVectorsOnModel(start, end, this.model) : undefined;
    const vectors = raycastedVectors || [start.clone(), end.clone()];

    disposeLineMaterial(line);
    const valid = !this.raycast || !this.model || !!raycastedVectors;
    line.material = valid ? Lines.createMaterial(DEFAULT_COLOR) : Lines.createMaterial(DEFAULT_INVALID_COLOR);

    const positions = new Float32Array(vectors.length * 3);
    line.geometry.setAttribute("position", new BufferAttribute(positions, 3));
    line.geometry.setFromPoints(vectors);

    Lines.getLineGeometry(line).setDrawRange(0, vectors.length);
    Lines.getLinePosition(line).needsUpdate = true;
    line.geometry.computeBoundingSphere();

    this.lineLengths = this.lineLengths.set(index, MeasurementValues.calculateDistanceFromArray(vectors));

    return valid;
  }

  /** Removes all lines */
  public clear(): void {
    this.lines.forEach(this.dispose);
    this.lines = this.lines.clear();
    this.lineLengths = this.lineLengths.clear();
  }

  private static createMaterial(color: ColorRepresentation): LineBasicMaterial {
    return new LineBasicMaterial({ color, depthTest: false });
  }

  /** Creates new Line instance */
  private static create(vectors: Vector3[]): Line {
    const geometry = new BufferGeometry();
    geometry.setDrawRange(0, vectors.length);
    geometry.setFromPoints(vectors);

    const line = new Line(geometry, Lines.createMaterial(DEFAULT_COLOR));

    scene.add(line);

    return line;
  }

  /** Removes line from scene and disposes its geometry and material */
  public dispose = (line?: Line): void => {
    if (!line) return;

    scene.remove(line);
    dispose(line);
  };

  /** Returns lines geometry position attribute */
  private static getLinePosition(line: Line): Float32BufferAttribute {
    return line.geometry.attributes.position as Float32BufferAttribute;
  }

  /** Returns lines geometry */
  private static getLineGeometry(line: Line) {
    return line.geometry;
  }
}
