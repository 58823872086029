import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Icon } from "components/Icon";
import { ConversionUnit } from "utils/format";
import { useUnitsStore } from "store";
import "./styles.css";

const units: ConversionUnit[] = ["m", "dm", "cm"];

export const UnitSelect = () => {
  const { unit, setUnit } = useUnitsStore();

  const handleChange = useCallback<h.JSX.GenericEventHandler<HTMLSelectElement>>(
    (e) => {
      const value = e.currentTarget.value as ConversionUnit;

      if (!units.includes(value)) return;

      setUnit(value);
    },
    [setUnit],
  );

  return (
    <div class="unit-select">
      <select value={unit} onChange={handleChange}>
        {units.map((u) => (
          <option key={u} value={u} selected={u === unit} default={u === "m"}>
            {u}
          </option>
        ))}
      </select>
      <Icon type={"angleSelect"} />
    </div>
  );
};
