import { ComponentChildren, h } from "preact";
import { Icon, IconType } from "components/Icon";
import "./styles.css";

type Header = { value: string; icon?: IconType; closeButton?: boolean };

interface Props {
  isOpen: boolean;
  header: Header;
  children: ComponentChildren;
  footer?: ComponentChildren;
  onClose?: () => any;
}

export const Modal = ({ isOpen, header, children, footer, onClose }: Props) => {
  if (!isOpen) return null;

  return (
    <div class="modal__backdrop">
      <div class="modal__box">
        <div class="modal__header">
          <div class="modal__header-value">
            {header.icon && <Icon type={header.icon} />}
            <span class="text-300 text-medium text-gray-900">{header.value}</span>
          </div>

          {header.closeButton && (
            <div class="modal__header-close" onClick={onClose}>
              <Icon type={"close"} />
            </div>
          )}
        </div>

        <div class="modal__content">{children}</div>

        <div class="modal__footer">{footer ?? null}</div>
      </div>
    </div>
  );
};

export * from "components/Modal/useModal";
