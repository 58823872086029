import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { Modal, useModal } from "components/Modal";
import { Checkbox } from "components/Checkbox";
import { Button } from "components/Button";
import { Link } from "components/Link";
import { useLocale } from "locale";
import { useUIStore } from "store";
import "./styles.css";

export const Warning = () => {
  const { t } = useLocale();
  const { warning, setWarning, setWarningPrevented, requestedMode, setMode, setRequestedMode } = useUIStore();

  const [checked, setChecked] = useState(false);
  const toggleCheckbox = useCallback(() => setChecked((checked) => !checked), []);

  const afterClose = useCallback(() => {
    setWarningPrevented(checked);
    setWarning(false);
  }, [checked, setWarning, setWarningPrevented]);

  const { isOpen, open, close } = useModal(afterClose);

  const back = useCallback(() => {
    close();
    afterClose();
  }, [close, afterClose]);

  const confirm = useCallback(() => {
    close();
    afterClose();
    setMode(requestedMode);
    setRequestedMode(undefined);
  }, [close, afterClose, setMode, requestedMode, setRequestedMode]);

  useEffect(() => {
    if (warning && !isOpen) open();
  }, [warning, isOpen, open]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={back}
      header={{ value: t("panel.warning.title"), icon: "exclamationMarkCircleRed", closeButton: true }}
      footer={
        <div class="warning__footer">
          <Checkbox checked={checked} label={t("panel.warning.doNotShow")} onClick={toggleCheckbox} />
          <div class="flex-center-v">
            <Link size={"lg"} onClick={back}>
              {t("panel.warning.back")}
            </Link>
            <Button onClick={confirm}>{t("panel.warning.quitAndRemove")}</Button>
          </div>
        </div>
      }
    >
      <p class="text-300 text-gray-900">{t("panel.warning.text")}</p>
      <br />
      <p class="text-300 text-gray-900">{t("panel.warning.recommendation")}</p>
    </Modal>
  );
};
