import { LinearSRGBColorSpace, OrthographicCamera, PerspectiveCamera, Scene, Texture, WebGLRenderer } from "three";
import { logException } from "utils/sentry";

/** Wrapper over WebGLRenderer to allow catch errors during instantiation and to separate renderer specific logic */
class Renderer {
  private renderer?: WebGLRenderer;

  constructor() {
    this.createRenderer();
  }

  /** Returns true if WebGLRenderer instance was created correctly */
  public get isCreated(): boolean {
    return this.renderer instanceof WebGLRenderer;
  }

  /** WebGLRenderer domElement property accessor */
  public get domElement(): HTMLCanvasElement | undefined {
    return this.renderer?.domElement;
  }

  public get canvasWidth(): number {
    return this.renderer?.domElement.clientWidth || 1;
  }

  public get canvasHeight(): number {
    return this.renderer?.domElement.clientHeight || 1;
  }

  /** WebGLRenderer render method accessor */
  public render(scene: Scene, camera: PerspectiveCamera | OrthographicCamera): void {
    this.renderer?.render(scene, camera);
  }

  /** Initial renderer setup after the model is loaded */
  public setRenderer(useLinearEncoding: boolean): void {
    if (!this.renderer) return;

    this.renderer.domElement.setAttribute("tabIndex", "0");
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio || 1);
    this.renderer.setClearColor("#f0f7ff");

    if (!useLinearEncoding) return;

    this.renderer.outputColorSpace = LinearSRGBColorSpace;
  }

  /** Updates renderer width and height - used on window resize */
  public update(): void {
    this.renderer?.setSize(window.innerWidth, window.innerHeight);
  }

  /** Inits texture (used for loading performance optimization) */
  public initTexture(texture: Texture): void {
    this.renderer?.initTexture(texture);
  }

  /** Focuses renderer canvas */
  public focus(): void {
    this.renderer?.domElement.focus();
  }

  /** Returns dataURL of current renderer canvas if available */
  public getScreenshot(scene: Scene, camera: PerspectiveCamera | OrthographicCamera): string {
    if (!this.renderer) return "";

    this.render(scene, camera);

    return this.renderer.domElement.toDataURL("image/jpeg");
  }

  /** Disposes WebGLRenderer instance */
  public dispose(): void {
    this.renderer?.dispose();
  }

  /** Creates new WebGLRenderer instance */
  private createRenderer(): void {
    const antialias = window.location.search.includes("&aa=true");

    try {
      this.renderer = new WebGLRenderer({
        powerPreference: "high-performance",
        antialias: antialias,
        stencil: false,
        alpha: false,
      });
    } catch (e) {
      logException(e);
    }
  }
}

export const renderer = new Renderer();
