export type Unit = "m" | "m²" | "m³" | "dm" | "dm²" | "dm³" | "cm" | "cm²" | "cm³" | "%";
export type ConversionUnit = "m" | "dm" | "cm";

export function formatValue(value?: number, addUnit = false, unit = "m", precision = 2): string {
  const fixed = value !== undefined ? value.toFixed(precision) : "—";

  return !addUnit ? fixed : `${fixed} ${unit}`;
}

export function convertFormattedValue(
  value?: number,
  addUnit = false,
  unit: Unit = "m",
  conversion: ConversionUnit = "m",
  precision = 4,
): string {
  if (!addUnit || conversion === "m" || unit === "%") return formatValue(value, addUnit, unit, precision);

  return formatValue(convertValue(value, unit, conversion), addUnit, unit.replace("m", conversion), precision);
}

/** Adds space as a thousands separator - whole numbers part only */
export function addThousandsSeparator(value: string): string {
  return value.replace(/(\d)(?=(?:[0-9]{3})+\.\b)/g, "$1 ");
}

export function convertValue(value?: number, unit: Unit = "m", conversion: ConversionUnit = "m"): number | undefined {
  if (value === undefined) return value;

  if (unit === "m" && conversion === "dm") return value * 10;
  if (unit === "m" && conversion === "cm") return value * 100;

  if (unit === "m²" && conversion === "dm") return value * 100;
  if (unit === "m²" && conversion === "cm") return value * 10000;

  if (unit === "m³" && conversion === "dm") return value * 1000;
  if (unit === "m³" && conversion === "cm") return value * 1000000;

  return value;
}

export function round(value: number, precision = 3): number {
  return parseFloat(value.toFixed(precision));
}

type Rgb = { r: number; g: number; b: number };

export function hexToRgb(hex: string, defaultRgb: Rgb = { r: 0, g: 0, b: 0 }): Rgb {
  if (hex.length !== 7) return defaultRgb;

  const parts = hex.replace("#", "").match(/.{1,2}/g);

  if (!parts) return defaultRgb;

  return {
    r: parseInt(parts[0], 16),
    g: parseInt(parts[1], 16),
    b: parseInt(parts[2], 16),
  };
}
