import {
  ColorRepresentation,
  Mesh,
  MeshBasicMaterial,
  MeshBasicMaterialParameters,
  SphereGeometry,
  Vector3,
} from "three";
import { Color, Label } from "viewer/geometry/Label";
import { scene, camera } from "viewer/core";
import { hexToRgb } from "utils/format";

const DEFAULT_COLOR = "#ffff00";

export class Dot {
  private readonly outerMesh: Mesh;
  private readonly innerMesh: Mesh;
  private label: Label | undefined;
  private labelValue = "";
  private highlighted = false;

  public lastColor?: ColorRepresentation;

  constructor(visible = true, color: ColorRepresentation = DEFAULT_COLOR) {
    this.outerMesh = Dot.createOuterMesh(color);
    this.innerMesh = Dot.createInnerMesh(color);

    if (!visible) this.hide();

    this.outerMesh.matrixAutoUpdate = false;
    this.innerMesh.matrixAutoUpdate = false;

    scene.add(this.outerMesh);
    scene.add(this.innerMesh);
  }

  /** Sets dots label with given value */
  public setLabel(value: string, bgColor?: Color, textColor?: Color, fontSize?: number): void {
    if (this.label) this.label.dispose();

    this.labelValue = value;
    this.label = new Label(this.position, value, bgColor, textColor, fontSize);

    this.scaleByCamera();
  }

  /** Toggles dots highlight */
  public toggleHighlight(): void {
    if (this.highlighted) {
      this.setLabel(this.labelValue);
      this.highlighted = false;
      return;
    }

    this.setLabel(this.labelValue, { ...hexToRgb("#70256d"), a: 0.9 }, { ...hexToRgb("#ffffff"), a: 1 }, 27);
    this.highlighted = true;
  }

  /** Sets dot as visible */
  public show() {
    this.outerMesh.visible = true;
    this.innerMesh.visible = true;
  }

  /** Sets dot as hidden */
  public hide() {
    this.outerMesh.visible = false;
    this.innerMesh.visible = false;
  }

  /** Returns vector with dots position */
  public get position(): Vector3 {
    return this.outerMesh.position;
  }

  /** Returns Mesh instance of outerMesh - used for raycasting */
  public get mesh(): Mesh {
    return this.outerMesh;
  }

  /** Sets new dot position */
  public setPosition(position: Vector3): void {
    this.outerMesh.position.copy(position);
    this.innerMesh.position.copy(position);
    this.label?.setPosition(position);
  }

  /** Scales dot by camera to preserve its size */
  public scaleByCamera(factor = 5): void {
    const perspective = camera.activeCamera === "perspective";
    const distance = this.outerMesh.position.distanceTo(camera.perspective.position);
    const zoom = camera.active.zoom;

    const scale = perspective ? distance / factor : 100 / zoom / factor / 1.35;
    const labelScale = perspective ? undefined : scale / 5.85;

    this.outerMesh.scale.set(scale, scale, scale);
    this.innerMesh.scale.set(scale, scale, scale);
    this.label?.scale(labelScale);

    this.outerMesh.updateMatrix();
    this.innerMesh.updateMatrix();
  }

  /** Removes dot from scene and disposes its geometry and texture */
  public dispose(): void {
    scene.remove(this.outerMesh, this.innerMesh);

    this.outerMesh.geometry.dispose();
    this.innerMesh.geometry.dispose();
    Dot.disposeMeshMaterial(this.outerMesh);
    Dot.disposeMeshMaterial(this.innerMesh);

    if (this.label) this.label.dispose();
  }

  /** Changes color of both meshes */
  public changeColor(color: ColorRepresentation = DEFAULT_COLOR, opacity = 0.5): void {
    this.lastColor = color;

    Dot.disposeMeshMaterial(this.outerMesh);
    this.outerMesh.material = Dot.createMeshMaterial(color, true, opacity);

    Dot.disposeMeshMaterial(this.innerMesh);
    this.innerMesh.material = Dot.createMeshMaterial(color);
  }

  /** Creates dots outer mesh instance */
  private static createOuterMesh(color: ColorRepresentation): Mesh {
    return new Mesh(new SphereGeometry(0.06, 16, 16), Dot.createMeshMaterial(color, true, 0.5));
  }

  /** Creates dots inner mesh instance */
  private static createInnerMesh(color: ColorRepresentation): Mesh {
    return new Mesh(new SphereGeometry(0.012, 8, 8), Dot.createMeshMaterial(color));
  }

  /** Creates sphere material */
  private static createMeshMaterial(
    color: ColorRepresentation,
    depthTest?: boolean,
    opacity?: number,
  ): MeshBasicMaterial {
    const parameters: MeshBasicMaterialParameters = { color };

    if (depthTest !== undefined) parameters.depthTest = depthTest;
    if (opacity !== undefined) parameters.transparent = true;
    if (opacity !== undefined) parameters.opacity = opacity;

    return new MeshBasicMaterial(parameters);
  }

  /** Disposes material of given mesh */
  private static disposeMeshMaterial(mesh: Mesh): void {
    if (Array.isArray(mesh.material)) return;

    mesh.material.dispose();
  }
}
