import { Triangle, Vector3 } from "three";
import { Record } from "immutable";
import { Volume } from "viewer/workers/interface";
import * as I from "viewer/measurement/interface";

const valuesFactory = Record<I.Values>({
  coordinates: [],
  lines: [],
  height: { min: 0, max: 0, diff: 0 },
  length: { real: 0, projected: 0 },
  perimeter: { real: 0, projected: 0 },
  area: { real: 0, projected: 0 },
  cursor: undefined,
  selected: undefined,
});

const volumeValuesFactory = Record<I.VolumeValues>({
  area: undefined,
  fill: undefined,
  cut: undefined,
  netFill: undefined,
  sampleCount: undefined,
  precision: undefined,
  incompleteness: undefined,
});

export class MeasurementValues {
  private values: Record<I.Values> = valuesFactory();
  private volumeValues: Record<I.VolumeValues> = volumeValuesFactory();
  private geodeticSurvey: Vector3 = new Vector3();

  /** Geodetic survey setter - used for Values calculation */
  public setGeodeticSurvey(geodeticSurvey: Vector3): void {
    this.geodeticSurvey = geodeticSurvey;
  }

  public addGeodeticSurvey(value: number, axis: "x" | "y" | "z"): number {
    return value + this.geodeticSurvey[axis];
  }

  public subtractGeodeticSurvey(value: number, axis: "x" | "y" | "z"): number {
    return value - this.geodeticSurvey[axis];
  }

  /** Returns calculated values as JS object */
  public getValues = (): I.Values => this.values.toJSON();

  public getVolumeValues = (defaultValues = false): I.VolumeValues => {
    return defaultValues ? volumeValuesFactory().toJSON() : this.volumeValues.toJSON();
  };

  /** Calculates new values and returns them as JS object */
  public update(
    vertices: Vector3[] = [],
    triangles: Triangle[] = [],
    lineLengths?: I.RealProjected[],
    cursor?: number,
    selected?: number,
  ): I.Values {
    const { coordinates, lines, length, perimeter, height } = this.calculateValuesFromVertices(
      vertices,
      this.geodeticSurvey,
      lineLengths,
    );

    const area = MeasurementValues.calculateTrianglesArea(triangles);

    this.values = valuesFactory({ coordinates, lines, length, height, perimeter, area, cursor, selected });

    return this.values.toJSON();
  }

  /** Calculates distance between two vertices */
  public static calculateDistanceFromArray(vectors: Vector3[]): I.RealProjected {
    if (vectors.length < 2) return { real: 0, projected: 0 };

    let real = 0;

    for (let i = 0; i < vectors.length; i++) {
      if (vectors[i + 1]) real += vectors[i].distanceTo(vectors[i + 1]);
    }

    const first = vectors[0];
    const last = vectors[vectors.length - 1];
    const projected = new Vector3(first.x, first.z).distanceTo(new Vector3(last.x, last.z));

    return { real, projected };
  }

  public setVolume(volume?: Volume): I.VolumeValues {
    this.volumeValues = volumeValuesFactory(volume);

    return this.getVolumeValues();
  }

  /** Clears values and returns them as JS object */
  public clear(): I.Values {
    this.values = valuesFactory();
    return this.values.toJSON();
  }

  /** Calculates all possible values from vertices - coordinates, lines, length, height, perimeter */
  private calculateValuesFromVertices(
    vertices: Vector3[],
    geodeticSurvey: Vector3,
    lineLengths?: I.RealProjected[],
  ): { coordinates: Vector3[]; lines: I.Line[]; length: I.Length; perimeter: I.Perimeter; height: I.Height } {
    const coordinates: Vector3[] = [];
    const lines: I.Line[] = [];
    const length: I.Length = { real: 0, projected: 0 };

    const perimeter: I.Perimeter = { real: 0, projected: 0 };

    const height: I.Height = { min: vertices[0]?.y || 0, max: vertices[0]?.y || 0, diff: 0 };

    // Line representing first coordinate (has zero values)
    if (vertices.length > 0) lines.push({ length: { real: 0, projected: 0 }, slope: 0, angle: 0 });

    for (let i = 0; i < vertices.length; i++) {
      const isLast = i === vertices.length - 1;
      const a = vertices[i];
      const b = !isLast ? vertices[i + 1] : vertices[0];
      const distance = MeasurementValues.calculateDistance(a, b);
      const lineLength = lineLengths ? lineLengths[i] : distance;

      // Coordinates
      coordinates.push(MeasurementValues.calculateRealCoordinate(a, geodeticSurvey));

      // Lines
      if (!isLast && vertices.length > 1) lines.push(MeasurementValues.calculateLine(lineLength, a, b));

      // Length
      if (!isLast) {
        length.real += distance.real;
        length.projected += distance.projected;
      }

      // Perimeter
      perimeter.real += vertices.length > 2 ? distance.real : 0;
      perimeter.projected += vertices.length > 2 ? distance.projected : 0;

      // Height - 1
      if (a.y < height.min) height.min = a.y;
      if (a.y > height.max) height.max = a.y;
    }

    // Height - 2
    height.diff = height.max - height.min;
    height.min = vertices.length ? this.geodeticSurvey.z + height.min : 0;
    height.max = vertices.length ? this.geodeticSurvey.z + height.max : 0;

    return { coordinates, lines, length, perimeter, height };
  }

  /** Calculates all possible values from triangles - area */
  private static calculateTrianglesArea(triangles: Triangle[]): I.Area {
    const area: I.Area = { real: 0, projected: 0 };

    for (const triangle of triangles) {
      const projectedTriangle = triangle.clone();
      projectedTriangle.a.setY(0);
      projectedTriangle.b.setY(0);
      projectedTriangle.c.setY(0);

      area.real += triangle.getArea();
      area.projected += projectedTriangle.getArea();
    }

    return area;
  }

  /** Calculates real world coordinates based on geodetic survey (xyz) - also flips y and z axis (Mawis standard) */
  private static calculateRealCoordinate(v: Vector3, xyz: Vector3): Vector3 {
    return new Vector3(xyz.x + v.x, xyz.z + v.y, xyz.y - v.z);
  }

  /** Calculates distance between two vertices */
  private static calculateDistance(a: Vector3, b: Vector3): I.RealProjected {
    const real = a.distanceTo(b);
    const projected = new Vector3(a.x, a.z).distanceTo(new Vector3(b.x, b.z));

    return { real, projected };
  }

  /** Calculates single line values */
  private static calculateLine(length: I.RealProjected, start: Vector3, end: Vector3): I.Line {
    const a = Math.sqrt((start.x - end.x) * (start.x - end.x) + (start.z - end.z) * (start.z - end.z));
    const b = start.y > end.y ? start.y - end.y : end.y - start.y;

    return {
      length: { ...length },
      slope: !a && !b ? 0 : Math.round(b / (a / 100)),
      angle: !a && !b ? 0 : parseFloat((Math.atan(b / a) * (180 / Math.PI)).toFixed(1)),
    };
  }
}
