import { h } from "preact";
import "./styles.css";

type Tab<T> = { key: T; label: string };

interface Props<T> {
  tabs: Tab<T>[];
  activeTab: T;
  onClick: (key: T) => any;
}

export const Tabs = <T extends string>({ tabs, activeTab, onClick }: Props<T>) => (
  <div class="tabs">
    {tabs.map(({ key, label }) => {
      const isActive = key === activeTab;
      const handleClick = () => onClick(key);

      return (
        <div key={key} class={`tabs__tab ${isActive ? "tabs__tab--active" : ""}`} onClick={handleClick}>
          <span>{label}</span>

          <div class={`tabs__tab-underline ${isActive ? "tabs__tab-underline--active" : ""}`} />
        </div>
      );
    })}
  </div>
);
