import { h } from "preact";
import { ApiError, Camera, Footer, Help, Mobile, Model, Panel, Warning } from "components";
import { useUIStore } from "store";
import "./styles.css";

export const Layout = () => {
  const { error } = useUIStore();

  if (error) return <ApiError error={error} />;

  return (
    <div class="layout">
      <Panel />
      <Camera />
      <Model />
      <Mobile />
      <Footer />

      <Warning />
      <Help />
    </div>
  );
};
