import { BufferGeometry, Float32BufferAttribute, GLBufferAttribute, Vector3 } from "three";

export function geometryToVectors(geometry: BufferGeometry): Vector3[] {
  const position = geometry.getAttribute("position");

  if (position instanceof GLBufferAttribute) return [];

  const vectors: Vector3[] = [];
  for (let i = 0; i < position.count; i++) {
    vectors.push(new Vector3(position.getX(i), position.getY(i), position.getZ(i)));
  }

  return vectors;
}

export function vectorsToPosition(vectors: Vector3[]): Float32BufferAttribute {
  return new Float32BufferAttribute(
    vectors.reduce<number[]>((p, c) => [...p, ...c.toArray()], []),
    3,
  );
}
