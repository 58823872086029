import { MapControls } from "three/examples/jsm/controls/MapControls";
import { isFirefox } from "utils/device";

// See src/@types directory for augmented ts modules

/** Augment three MapControls with zoomIn and zoomOut functions */
export function augmentMapControls(): void {
  MapControls.prototype.zoomIn = function () {
    const event = new WheelEvent("wheel", { deltaY: -50 });
    this.domElement?.dispatchEvent(event);
  };
  MapControls.prototype.zoomOut = function () {
    const event = new WheelEvent("wheel", { deltaY: 50 });
    this.domElement?.dispatchEvent(event);
  };
  MapControls.prototype.lookDown = function () {
    const pointerId = isFirefox ? 0 : 1;
    const eventCommon = { pointerId, pointerType: "mouse", button: 2, clientX: 0 };

    const deltaY = window.innerHeight / 2;
    const increment = deltaY / 60;

    let i = 0;
    const interval = window.setInterval(() => {
      this.domElement?.dispatchEvent(new PointerEvent("pointerdown", { ...eventCommon, clientY: 0 }));
      this.domElement?.dispatchEvent(new PointerEvent("pointermove", { ...eventCommon, clientY: i }));
      this.domElement?.dispatchEvent(new PointerEvent("pointerup", { ...eventCommon }));

      i += increment;

      if (i >= deltaY) window.clearInterval(interval);
    }, 10);
  };
}
