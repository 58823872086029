import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Icon, IconType } from "components/Icon";
import "./styles.css";

interface Props<T> {
  icon: IconType;
  value?: T;
  title?: string;
  active?: boolean;
  showText?: boolean;
  onClick?: (value?: T) => any;
}

export const Button = <T extends string | undefined>({
  icon,
  value,
  title,
  active = false,
  showText = false,
  onClick = () => {},
}: Props<T>) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(value);
    },
    [onClick, value],
  );

  return (
    <div
      class={`toolbar__button ${showText ? "toolbar__button--text" : ""} ${active ? "toolbar__button--active" : ""}`}
      title={title}
      onClick={handleClick}
    >
      <Icon type={icon} />
      {showText && <span>{title}</span>}
    </div>
  );
};
