import { ComponentChildren, createContext, h } from "preact";
import { Dispatch, useEffect, useState } from "preact/hooks";
import { resolveLocaleFromUrl } from "locale/utils/resolver";
import i18next from "i18next";

import cs from "locale/resources/cs.json";
import en from "locale/resources/en.json";
import uk from "locale/resources/uk.json";
import de from "locale/resources/de.json";

export enum Languages {
  cs = "cs-CZ",
  en = "en-US",
  uk = "uk-UA",
  de = "de-DE",
}

const DEFAULT_LOCALE = resolveLocaleFromUrl<Languages>([
  ["cs", Languages.cs],
  ["en", Languages.en],
  ["uk", Languages.uk],
  ["de", Languages.de],
]);

export const Context = createContext({ locale: DEFAULT_LOCALE, setLocale: (() => {}) as Dispatch<Languages> });

interface Props {
  children: ComponentChildren;
}

export const Locale = ({ children }: Props) => {
  const [locale, setLocale] = useState<Languages>(DEFAULT_LOCALE);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    i18next
      .init({
        lng: DEFAULT_LOCALE,
        fallbackLng: Languages.cs,
        resources: {
          [Languages.cs]: { translation: cs },
          [Languages.en]: { translation: en },
          [Languages.uk]: { translation: uk },
          [Languages.de]: { translation: de },
        },
      })
      .finally(() => setInitialized(true));
  }, []);

  useEffect(() => {
    i18next.changeLanguage(locale).finally();
  }, [locale]);

  if (!initialized) return null;

  return <Context.Provider value={{ locale, setLocale }}>{children}</Context.Provider>;
};
