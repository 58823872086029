import { Sprite, SpriteMaterial, Texture, Vector2, Vector3 } from "three";
import { scene } from "viewer/core";

export type Color = { r: number; g: number; b: number; a: number };

const DEFAULT_BG: Color = { r: 0, g: 0, b: 0, a: 0.7 };
const DEFAULT_TEXT: Color = { r: 255, g: 255, b: 255, a: 0.9 };
const DEFAULT_FONT_SIZE = 26;

export class Label {
  private readonly sprite: Sprite | undefined;

  constructor(
    position: Vector3,
    value: string,
    bgColor: Color = DEFAULT_BG,
    textColor: Color = DEFAULT_TEXT,
    fontSize: number = DEFAULT_FONT_SIZE,
  ) {
    this.sprite = Label.create(value, bgColor, textColor, fontSize);

    if (this.sprite) {
      this.sprite.position.copy(position);
      scene.add(this.sprite);
    }
  }

  /** Sets new position */
  public setPosition(position: Vector3): void {
    this.sprite?.position.copy(position);
  }

  /** Scales the sprite */
  public scale(scale = 0.035): void {
    this.sprite?.scale.set(scale, scale, 1);
  }

  /** Removes label from scene and disposes its geometry and material */
  public dispose(): void {
    if (!this.sprite) return;

    scene.remove(this.sprite);

    this.sprite.geometry.dispose();
    this.sprite.material.dispose();
  }

  /** Creates new label sprite instance */
  private static create(
    value: string,
    bgColor: Color = DEFAULT_BG,
    textColor: Color = DEFAULT_TEXT,
    fontSize: number = DEFAULT_FONT_SIZE,
  ): Sprite | undefined {
    const fontFace = "Arial, Helvetica, sans-serif";

    const canvas = document.createElement("canvas");
    canvas.width = 64;
    canvas.height = 64;

    const context = canvas.getContext("2d");

    if (!context) return undefined;

    // bg color
    context.fillStyle = `rgba(${bgColor.r}, ${bgColor.g}, ${bgColor.b}, ${bgColor.a})`;
    context.font = `${fontSize}px ${fontFace}`;

    const metrics = context.measureText(value);
    const textWidth = metrics.width;

    Label.drawShape(context, 64, 10, 8);

    // text color
    context.fillStyle = `rgba(${textColor.r}, ${textColor.g}, ${textColor.b}, ${textColor.a})`;
    context.fillText(value, 32 - textWidth / 2, 42);

    const texture = new Texture(canvas);
    texture.needsUpdate = true;

    const spriteMaterial = new SpriteMaterial({ map: texture, sizeAttenuation: false, depthTest: false });
    const sprite = new Sprite(spriteMaterial);
    sprite.scale.set(0.035, 0.035, 1);
    sprite.center = new Vector2(0.5, -0.4);

    return sprite;
  }

  /** Draws labels shape to canvas context */
  private static drawShape(ctx: CanvasRenderingContext2D, side: number, offset: number, radius: number) {
    const a = side - offset;
    const half = side / 2;

    ctx.beginPath();

    ctx.moveTo(offset + radius, offset);
    ctx.lineTo(a - radius, offset);
    ctx.quadraticCurveTo(a, offset, a, offset + radius);
    ctx.lineTo(a, a - radius);
    ctx.quadraticCurveTo(a, a, a - radius, a);

    ctx.lineTo(half + offset, a);
    ctx.lineTo(half, side);
    ctx.lineTo(half - offset, a);

    ctx.lineTo(offset + radius, a);
    ctx.quadraticCurveTo(offset, a, offset, a - radius);
    ctx.lineTo(offset, offset + radius);
    ctx.quadraticCurveTo(offset, offset, offset + radius, offset);

    ctx.closePath();
    ctx.fill();
  }
}
