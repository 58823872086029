import { Group, Vector3 } from "three";
import { DEFAULT_METHOD, SettingsInput, Volume } from "viewer/measurement/Volume";
import { MeasurementValues } from "viewer/measurement/Values";
import { Raycasting } from "viewer/measurement/Raycasting";
import { Geometry } from "viewer/measurement/Geometry";
import { Handlers } from "viewer/measurement/Handlers";
import * as I from "viewer/measurement/interface";
import { modifyVectorsY } from "viewer/utils";
import { Cursor } from "viewer/geometry";
import { worker } from "viewer/workers";

const INVALID_SELECTION_COLOR = "#ff0000";

export class Measurement extends Handlers {
  private mode: I.Mode;
  private controlMode: I.ControlMode;
  private previousControlMode: I.ControlMode;

  private readonly raycasting: Raycasting;
  private readonly cursor: Cursor = new Cursor();
  private readonly volume = new Volume();

  private selected: number | undefined = undefined;
  private selectedPreviousPosition: Vector3 | undefined = undefined;
  private validSelectedChange = true;

  public readonly geometry = new Geometry();
  public values: MeasurementValues = new MeasurementValues();

  constructor() {
    super();
    this.raycasting = new Raycasting(this.handleLeftClick, this.handleLeftClickWithCommand, this.handleRightClick);
  }

  /** Getters */
  public getMode = (): I.Mode => this.mode;
  public getControlMode = (): I.ControlMode => this.controlMode;

  /** Public coordinate manipulation methods */
  public addCoordinate = (position: Vector3): void => this.addDot(position);
  public removeCoordinate = (index: number): void => this.removeDot(index);
  public selectCoordinate = (index: number): void => this.startEditControlMode(index);

  /** Public volume measurement methods */
  public calculateVolume = (): Promise<void> => this.volume.calculate(this.geometry, this.values, this.onVolumeChange);
  public abortVolume = (): void => this.volume.abort(this.geometry);
  public setVolume = (input: SettingsInput): void => this.handleSettingsChange(input);

  /** Sets measurement function - disabled if undefined */
  public setMode(mode?: I.Mode): void {
    this.mode === "volume" && this.abortVolume();
    this.mode = mode;
    this.reset();
  }

  /** Model setter - used for raycasting and volume performance test */
  public initModel(model: Group): void {
    this.cursor.setModel(model);
    this.geometry.setModel(model);
    this.raycasting.setModel(model);
  }

  /** Update for each animation frame */
  public update(force = false): void {
    this.raycast(force);
  }

  /** Checks if values has at least one coordinate without cursor */
  public hasCoordinates = (): boolean => {
    const { coordinates, cursor } = this.values.getValues();
    const hasCursor = cursor !== undefined;

    return (!hasCursor && coordinates.length > 0) || (hasCursor && coordinates.length > 1);
  };

  /** Simulates right mouse button click - used for touch devices */
  public simulateRightClick = (): void => {
    this.handleRightClick();
  };

  /** Checks new mouse position and if changed, applies the changes to the geometry */
  private raycast(force: boolean): void {
    if (!this.mode || !this.controlMode) return;

    const previousPosition = this.raycasting.position;
    const position = this.raycasting.raycastModel(force);

    if (this.raycasting.positionEquals(previousPosition)) return;

    // In Edit mode, move selected dot and lines connected to it
    if (this.controlMode === "edit" && position) this.updateSelectedDot(position);

    // In add mode, update cursor if position on model has changed
    if (this.controlMode === "add") {
      this.cursor.update(position, this.geometry.hasLines ? this.geometry.lastVector : undefined);
    }

    this.updateValues(this.geometry.vectors, position);
  }

  /** Calculates new values and calls onValuesChange (sends them to UI) */
  private updateValues(vectors?: Vector3[], cursorPosition?: Vector3): void {
    if (!vectors) {
      const newValues = this.values.clear();
      this.onValuesChange(newValues);
      return;
    }

    let cursorIndex: number | undefined = undefined;

    if (this.controlMode === "add" && cursorPosition) cursorIndex = vectors.push(cursorPosition.clone()) - 1;

    const isHorizontal = this.volume.methodEquals("horizontal");
    const flatVertices = this.geometry.hasPolygon && isHorizontal;
    const polygonVertices = !flatVertices ? vectors : modifyVectorsY(vectors, this.volume.getY());

    const newValues = this.values.update(
      vectors,
      this.geometry.hasPolygon ? this.geometry.polygon.update(polygonVertices) : undefined,
      this.geometry.hasRaycastedLines ? [...this.geometry.lengths, this.cursor.lineLength] : undefined,
      cursorIndex,
      this.selected,
    );

    // Fix polygon values if horizontal
    if (isHorizontal) {
      newValues.perimeter.real = newValues.perimeter.projected;
      newValues.area.real = newValues.area.projected;
    }

    this.onValuesChange(newValues);
  }

  /** Sets new control mode and calls UI callbacks */
  private setControlMode(controlMode: I.ControlMode): void {
    this.previousControlMode = this.controlMode;
    this.controlMode = controlMode;

    this.onControlModeChange(this.controlMode);
    this.updateValues(this.geometry.vectors);
  }

  /** Starts add control mode */
  private startAddControlMode(): void {
    if (this.controlMode === "add") return;

    this.geometry.openPolygon();

    this.setControlMode("add");
    this.handleGeometryChange();
  }

  /** Ends add control mode */
  private endAddControlMode(): void {
    if (this.controlMode !== "add") return;

    if (this.geometry.hasPolygon && this.geometry.validPolygonVectors && !this.geometry.polygonVisible) return;
    if (this.mode === "volume" && this.geometry.validPolygonVectors && !this.geometry.validPolygonLines) return;

    this.geometry.closePolygon();
    this.cursor.update(undefined); // Hide cursor

    this.setControlMode(undefined);
    this.handleGeometryChange();

    this.firstVolumeCalculation();
  }

  /** Starts edit control mode for dot with given index */
  private startEditControlMode(index?: number): void {
    if (this.controlMode === "edit" || this.selected !== undefined) return;

    if (index === undefined) index = this.raycasting.intersectMeshes(this.geometry.meshes);
    if (index === undefined) return;

    const dot = this.geometry.toggleDotHighlight(index);

    if (!dot) return;

    if (this.controlMode === "add") this.geometry.closePolygon();
    this.cursor.update(undefined); // Hide cursor

    this.selected = index;
    this.selectedPreviousPosition = dot.position.clone();

    this.setControlMode("edit");
    this.handleGeometryChange();
    this.registerEditControlModeListener();
  }

  /** Ends edit control mode and sets previous control mode - submit or cancel */
  private endEditControlMode(cancel = false): void {
    if (this.controlMode !== "edit" || this.selected === undefined || (!cancel && !this.validSelectedChange)) return;

    this.geometry.toggleDotHighlight(this.selected);

    if (cancel && this.selectedPreviousPosition) this.updateSelectedDot(this.selectedPreviousPosition);
    if (cancel && this.mode === "volume") {
      this.geometry.volume.show();
      this.volume.showValues(this.values, this.onVolumeChange);
    }

    this.selected = undefined;
    this.selectedPreviousPosition = undefined;

    this.removeEditControlModeListener();

    if (this.previousControlMode === "add") this.startAddControlMode();
    if (!this.previousControlMode) this.setControlMode(undefined);

    if (!cancel) this.handleGeometryChange(true);

    this.firstVolumeCalculation();
  }

  /** Tries to run first automatic volume calculation if possible */
  private firstVolumeCalculation(): void {
    if (this.mode !== "volume" || this.controlMode || !this.volume.firstCalculation) return;
    if (!this.geometry.polygonVisible) return;

    this.calculateVolume().finally();
  }

  /** Adds new coordinate/dot with given position (left click) */
  private addDot = (position: Vector3): void => {
    if (this.volume.inProgress) return;

    const lastVector = this.geometry.lastVector;

    if (this.geometry.hasLines && lastVector) this.geometry.addLine([lastVector, position]);

    this.geometry.addDot(position);

    this.updateValues(this.geometry.vectors);

    this.handleGeometryChange(true);
  };

  /** Removes a coordinate/dot with given index */
  private removeDot = (index: number): void => {
    if (this.controlMode === "edit" || this.volume.inProgress) return;

    this.geometry.removeDot(index, this.geometry.hasLines, this.controlMode !== "add" && this.geometry.hasPolygon);

    if (this.geometry.hasLines && this.controlMode === "add") this.cursor.updateLineOrigin(this.geometry.lastVector);

    this.updateValues(this.geometry.vectors, this.cursor.lastPosition);

    this.handleGeometryChange(true);
  };

  /** Makes changes to selected dot in the edit control mode - also used for canceling the edit mode */
  private updateSelectedDot(position: Vector3): void {
    if (this.selected === undefined) return;

    const dot = this.geometry.setDotPosition(this.selected, position);

    if (!dot || !this.geometry.hasLines) return;

    const valid = this.updateConnectedLines(this.selected, position);

    if (!valid && dot.lastColor !== INVALID_SELECTION_COLOR) dot.changeColor(INVALID_SELECTION_COLOR);
    if (valid && dot.lastColor === INVALID_SELECTION_COLOR) dot.changeColor();

    this.validSelectedChange = valid;
  }

  /** Updates lines connected to the selected (edited) dot and returns false if at least one updated line is invalid */
  private updateConnectedLines(index: number, position: Vector3): boolean {
    if (this.geometry.dots.length === 1) return true;

    let valid = true;

    const previousDot = !this.geometry.hasPolygon && index - 1 < 0 ? undefined : this.geometry.getDot(index - 1);
    if (previousDot && !this.geometry.updateLine(index - 1, [previousDot.position, position])) valid = false;

    const nextDot = this.geometry.getDot(index + 1) || (this.geometry.hasPolygon ? this.geometry.getDot(0) : undefined);
    if (nextDot && !this.geometry.updateLine(index, [position, nextDot.position])) valid = false;

    return valid;
  }

  /** For volume measurement - settings change handler */
  private handleSettingsChange(input: SettingsInput): void {
    const settings = this.volume.set(this.values, input, () => {
      this.volume.hideValues(this.values, this.onVolumeChange);
      this.geometry.volume.hide();
    });

    if (!settings) return;

    this.updateValues(this.geometry.vectors, this.cursor.lastPosition);

    this.onSettingsChange(
      settings.method,
      settings.precision,
      settings.y,
      !this.volume.firstCalculation && this.geometry.validPolygonVectors && !this.controlMode,
    );
  }

  /** For volume measurement - geometry change handler */
  private handleGeometryChange(removePoints = false): void {
    if (this.mode !== "volume" || this.volume.firstCalculation) return;

    this.onGeometryChange(this.geometry.validPolygonVectors && this.geometry.polygonVisible && !this.controlMode);

    removePoints ? this.geometry.volume.removePoints() : this.geometry.volume.hide();
    removePoints
      ? this.volume.clearValues(this.values, this.onVolumeChange)
      : this.volume.hideValues(this.values, this.onVolumeChange);
  }

  /** Left mouse click handler */
  private handleLeftClick: I.LeftClickHandler = (position): void => {
    if (!this.mode || this.volume.inProgress) return;

    // Start add mode
    if (!this.controlMode) return this.startAddControlMode();

    // Submit edit mode
    if (this.controlMode === "edit") return this.endEditControlMode();

    // End add mode by first dot click
    const existingDotIndex = this.raycasting.intersectMeshes(this.geometry.meshes);
    if (this.geometry.hasPolygon && existingDotIndex === 0) return this.endAddControlMode();

    // Submit new dot in add mode
    if (position && existingDotIndex === undefined && this.cursor.valid) this.addCoordinate(position);
  };

  /** Left mouse click with ctrl/cmd (based on system) handler */
  private handleLeftClickWithCommand: I.LeftClickWithCommandHandler = (): void => {
    if (!this.mode || this.volume.inProgress) return;

    this.startEditControlMode();
  };

  /** Right mouse click handler */
  private handleRightClick: I.RightClickHandler = () => {
    if (!this.mode || this.volume.inProgress) return;

    // End add mode
    if (this.controlMode === "add") this.endAddControlMode();

    // End edit mode (cancel)
    if (this.controlMode === "edit") this.endEditControlMode(true);
  };

  /** Escape key handler - cancel the edit control mode */
  private handleKeyDown = (e: KeyboardEvent): void => {
    if (!this.mode || this.volume.inProgress) return;

    // End edit mode (cancel)
    if (e.code === "Escape" && this.controlMode === "edit") this.endEditControlMode(true);
  };

  /** Resets everything by current mode */
  private reset(): void {
    this.geometry.hasLines = this.mode === "distance" || this.mode === "area" || this.mode === "volume";
    this.geometry.hasPolygon = this.mode === "area" || this.mode === "volume";
    this.geometry.hasRaycastedLines = this.mode === "volume";

    this.selectedPreviousPosition = undefined;
    this.selected = undefined;

    this.volume.firstCalculation = true;

    this.geometry.clear();

    this.cursor.update();
    this.cursor.setLineRaycast(this.geometry.hasRaycastedLines);
    this.geometry.setLinesType(this.geometry.hasRaycastedLines ? "projected" : "default");
    this.setVolume({ method: DEFAULT_METHOD });

    if (!this.mode) this.setControlMode(undefined);
    if (this.mode) this.startAddControlMode();

    this.updateValues();
  }

  /** Cleanup - geometry and listeners disposal  */
  public cleanup = (): void => {
    worker.abort(false);
    this.raycasting.cleanup();
    this.geometry.cleanup();
    this.cursor.cleanup();
    this.values.clear();
    this.clearHandlers();
  };

  private registerEditControlModeListener(): void {
    window.addEventListener("keydown", this.handleKeyDown, false);
  }

  private removeEditControlModeListener(): void {
    window.removeEventListener("keydown", this.handleKeyDown, false);
  }
}
